import React from 'react';
import { PageTitle } from '../../common/PageTitle/PageTitle';
import './CertificateList.scss';
import { CertificateCell } from './CertificateCell';
import { CertificateItem } from '../../../utilities/models';
import { useQuery } from 'react-query';
import { LoadingSpinner } from '../../utilities/LoadingSpinner/LoadingSpinner';
import { getAllCertificatesFromDynamoDB } from '../../../utilities/aws/awsUtils';

export const CertificateList = () => {
  const { data: courses } = useQuery<CertificateItem[]>(
    ['CertificateList', 'courses_list'],
    () => getAllCertificatesFromDynamoDB()
  );

  return (
    <>
      <PageTitle title="Certificates" />
      <h2 className="global__section_divider">Certifications</h2>
      {courses ? (
        courses?.map((certificate: CertificateItem) => {
          return <CertificateCell certificate={certificate} />;
        })
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
